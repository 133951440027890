export const maskCpf = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const maskPhone = (value) => {
  // Remove all non-digit characters
  value = value.replace(/\D/g, "");

  if (value.length > 11) value = value.slice(0, 11);

  // Apply the appropriate mask based on the length of the value
  if (value.length > 6) {
    value = value.replace(/^(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3");
  } else if (value.length > 2) {
    value = value.replace(/^(\d{2})(\d{0,5})/, "($1) $2");
  } else {
    value = value.replace(/^(\d{0,2})/, "($1");
  }

  return value;
};

export const maskCnpj = (value) => {
  return value
    .replace(/\D+/g, "") // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2") // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const maskDbName = (value) => {
  return value
    .replace(/[^a-zA-Z_\s]|[\d]/g, "")
    .replace(/\s/g, "_")
    .toLowerCase();
};

export const maskLandline = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

export const maskCaepf = (value) => {
  return value.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3/$4-$5",
  );
};

export const maskCep = (value) => {
  return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");
};

export const maskBrl = (value) => {
  return value.replace(/\D/g, "").replace(/(\d{1,2})$/, ".$1");
};

export const removeMask = (value) => {
  return value.replace(/\D/g, "");
};
