import { useHistory } from "react-router-dom";
import "../../assets/css/global.css";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import putUser from "repositories/users/putUser";
import EditUserForm from "components/Forms/EditUserForm";

function EditUser() {
  const { id } = useParams();
  const history = useHistory();

  const handleSubmit = async (data) => {
    const toastId = toast.loading('Atualizando empresa...');
    try {
      await putUser(id, {
        ...data,
        pis: parseInt(data.pis),
        loginweb: Number(data.loginweb),
        loginapp: Number(data.loginapp),
        status: Number(data.status),
        funcionarios: "[]",
        permissoes: "[]",
        tipo: "gestor_sistema",
      });

      toast.success("Usuário alterado com sucesso!", { autoClose: 500 });

      setTimeout(() => {
        history.push("/admin/usuarios");
      }, 2000);
    } catch (err) {
      toast.error(err.message);
    }
    toast.dismiss(toastId);
  };

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="title">Dados do Usuário</h3>
              </CardHeader>
              <CardBody>
                <EditUserForm
                  id={id}
                  onSubmit={handleSubmit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditUser;
