import axios from "axios";

export default async function getCompany(id) {
  const { data } = await axios
    .get(`${process.env.REACT_APP_BASE_URL}/v1/empresa/?id=${id}`)
    .then((res) => res)
    .catch((error) => {
      throw error.response.data.data;
    });

  return data.response;
}
