import { useHistory } from "react-router-dom";
import "../../assets/css/global.css";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import { toast, ToastContainer } from "react-toastify";
import postAccess from "../../repositories/accesses/postAccess";
import CreateAccessForm from "components/Forms/CreateAccessForm";

function CreateAccess() {
  document.title = "Criar Acesso";

  const history = useHistory();

  const handleSubmit = async (data) => {
    try {
      await postAccess({
        idEmpresa: data.empresa,
        idUsuario: data.usuario,
        funcionarios: "[]",
        permissoes: "[]",
      });

      toast.success("Acesso criado com sucesso!", { autoClose: 500 });

      setTimeout(() => {
        history.push("/admin/acessos");
      }, 2000);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="title">Dados do Acesso</h3>
              </CardHeader>
              <CardBody>
                <CreateAccessForm onSubmit={handleSubmit} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CreateAccess;
