const UseStorage = (type, key, value) => {
  switch (type) {
    case "set":
      localStorage.setItem(key, JSON.stringify(value));
      break;
    case "get":
      const response = localStorage.getItem(key);
      return JSON.parse(response || "false");
    case "remove":
      localStorage.removeItem(key);
      break;
    default:
      break;
  }
};

export default UseStorage;
