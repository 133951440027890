import { useState } from "react";
import { Button, Col, FormGroup, Input } from "reactstrap";
import { BsSearch } from "react-icons/bs";
import { removeMask } from "utils/masks";

export default function FilterSearch({ fieldsToFilter, onSearch }) {
  const [filteredField, setFilteredField] = useState("");
  const [search, setSearch] = useState("");

  const handleFilter = (e) => {
    setFilteredField(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const searchValue = filteredField === "cnpj" ? removeMask(search) : search;

    onSearch({
      field: filteredField,
      value: searchValue.toUpperCase(),
    });
  };

  return (
    <>
      <Col md="3">
        <FormGroup>
          <select onChange={handleFilter} className="form-control">
            <option selected disabled>
              Selecione um campo
            </option>
            {fieldsToFilter.map((e) => (
              <option value={e.value} key={e.value}>
                {e.name}
              </option>
            ))}
          </select>
        </FormGroup>
      </Col>
      <Col md="5" style={{ display: "flex", justifyContent: "end" }}>
        <form onSubmit={handleSearch} style={{ display: "flex", gap: 10 }}>
          <Input
            placeholder={filteredField}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            disabled={!filteredField}
          />
          <Button
            type="submit"
            style={{
              display: "flex",
              gap: 10,
              height: 38,
              padding: "10px 30px",
              margin: 0,
              overflow: "inherit",
              color: "white",
            }}
            disabled={!filteredField}
          >
            Pesquisar
            <i>
              <BsSearch size={20} />
            </i>
          </Button>
        </form>
      </Col>
    </>
  );
}
