import React from "react";

const SelectBillingTriggerTypes = ({ control, intervalCount, ...props }) => {
  return (
    <select {...props}>
      <option disabled selected>
        Selecione o periodo de cobrança
      </option>
      <option key={"beginning_of_period"} value={"beginning_of_period"}>
        Início do periodo
      </option>
      <option key={"end_of_period"} value={"end_of_period"}>
        Fim do periodo
      </option>
      {intervalCount == 1 && (
        <option key={"day_of_month"} value={"day_of_month"}>
          Dia do mês
        </option>
      )}
    </select>
  );
};

export default SelectBillingTriggerTypes;
