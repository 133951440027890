import axios from "axios";

export default async function getCnpj(cnpj) {
  const { data } = await axios
    .get(`https://publica.cnpj.ws/cnpj/${cnpj}`)
    .then((res) => res)
    .catch((error) => {
      console.error("Erro ao buscar o CNPJ!", error);
    });

  return data;
}
