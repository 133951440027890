import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";

export default function Actions({ link, toggleConfirmModal }) {
  return (
    <td>
      <div style={{ display: "flex", gap: 7 }}>
        {link ? (
          <Link to={link}>
            <Button className="btn-icon" color="success" size="sm">
              <i className="fa fa-edit"></i>
            </Button>
          </Link>
        ) : null}

        <Button
          onClick={toggleConfirmModal}
          className="btn-icon"
          color="danger"
          size="sm"
        >
          <i className="fa fa-times" />
        </Button>
      </div>
    </td>
  );
}
