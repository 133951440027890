import axios from "axios";
import { toast } from "react-toastify";
const apiBase = process.env.REACT_APP_BASE_URL;

const useCompany = () => {
  const getCompany = async () => {
    const response = await axios.get(`${apiBase}/v1/empresas`);
    if (response.data) {
      return response.data;
    }
    return [];
  };

  const getUserAssociation = async (objUser) => {
    const response = await axios.get(
      `${apiBase}/v1/empresas/usuario?filter=${JSON.stringify(objUser)}`,
    );
    if (response.data) {
      return response.data;
    }
    return [];
  };

  const getResearchedCompanies = async (page, numberPage, objFilter) => {
    const response = await axios.get(
      `${apiBase}/v1/empresas/?page=${page}&limit=${numberPage}&filter=${JSON.stringify(
        objFilter,
      )}`,
    );
    if (response.data) {
      return response.data;
    }
    return [];
  };

  const getCompanyPagination = async (selectedPage, numberPage) => {
    const response = await axios.get(
      `${apiBase}/v1/empresas/?page=${selectedPage}&limit=${numberPage}`,
    );
    if (response.data) {
      return response.data;
    }
    return [];
  };

  const getOneCompany = async (id) => {
    const response = await axios.get(`${apiBase}/v1/empresa/?id=${id}`);
    if (response.data) {
      return response.data;
    }
    return [];
  };

  const updateCompany = async (id, data) => {
    const response = await axios.put(
      `${apiBase}/v1/empresa/editar-empresa/?id=${id}`,
      data,
    );

    if (response.data) {
      return response.data;
    }
    return [];
  };

  const postCompany = async (companyData) => {
    const response = await axios.post(
      `${apiBase}/v1/empresa/criar-empresa`,
      companyData,
    );
    if (response.data) {
      return response.data;
    }
    return [];
  };

  const deleteCompany = async (id) => {
    const response = await toast.promise(
      axios.put(`${apiBase}/v1/empresa/desativar-empresa/?id=${id}`),
      {
        pending: "Carregando",
        success: "Empresa Desativada com Sucesso!!",
        error: "Ocorreu um erro!!",
      },
    );
    return response;
  };

  return {
    getCompanyPagination,
    getCompany,
    getResearchedCompanies,
    postCompany,
    getOneCompany,
    updateCompany,
    deleteCompany,
    getUserAssociation,
  };
};

export default useCompany;
