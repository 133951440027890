import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UseStorage from "hooks/UseStorage";

function Admin() {
  const history = useHistory();

  useEffect(() => {
    const token = UseStorage("get", "token");
    if (!token) {
      history.push("/login");
    }
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            exact={true}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <React.Fragment>
          <div className="wrapper">
            <Sidebar
              routes={routes}
              logo={{
                outterLink: "/admin",
                text: "Admin Dot8",
                imgSrc: logo,
              }}
            />
            <div className="main-panel" data={color}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/admin/empresas" />
              </Switch>
            </div>
          </div>
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
