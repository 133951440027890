import axios from "axios";
import UseStorage from "hooks/UseStorage";

export default async function deleteAccess(id) {
  const token = UseStorage("get", "token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  await axios
    .delete(`${process.env.REACT_APP_AUTH_SERVER}/empresausuario/${id}`, config)
    .then((res) => res)
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
}
