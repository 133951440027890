import { toast } from "react-toastify";
import { Input } from "reactstrap";
import getCep from "repositories/cep/getCep";
import { maskCep } from "utils/masks";

export const CepInput = ({ setValue, ...field }) => {
  const handleCep = (data) => {
    setValue("endereco", data.logradouro);
    setValue("bairro", data.bairro);
    setValue("cidade", data.localidade);
    setValue("estado", data.uf);
  };

  const handleInput = async (e) => {
    const { value } = e.target;

    field.onChange(maskCep(value));
    console.log(value);

    if (value.length != 8) return;

    const toastId = toast.loading("Buscando dados do CEP, aguarde...");

    const cepData = await getCep(value);
    toast.dismiss(toastId);
    if (cepData.erro) {
      toast.error("Cep inválido!");
    }

    handleCep(cepData);
  };

  return <Input {...field} maxLength={9} onChange={handleInput} />;
};
