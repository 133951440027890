import { useState } from "react";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import getCnpj from "repositories/cnpj/getCnpj";
import validateCnpj from "utils/cnpj";
import { removeMask } from "utils/masks";
import { maskCep } from "utils/masks";
import { maskCnpj } from "utils/masks";

export const CnpjInput = ({ setValue, validateDocument, setValidateDocument, ...field }) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleCnpj = ({ estabelecimento, razao_social, nome_fantasia }) => {
    if(estabelecimento) {
      if(estabelecimento.logradouro) setValue("endereco", estabelecimento.logradouro);
      if(estabelecimento.bairro) setValue("bairro", estabelecimento.bairro);
      if(estabelecimento.cidade?.nome) setValue("cidade", estabelecimento.cidade.nome);
      if(estabelecimento.estado?.sigla) setValue("estado", estabelecimento.estado.sigla);
      if(estabelecimento.cep) setValue("cep", maskCep(estabelecimento.cep));
    };
    if(razao_social) setValue("razaosocial", razao_social);
    if(nome_fantasia) setValue("nomefantasia", nome_fantasia);
  };

  const handleInput = async (e) => {
    const { value } = e.target;

    field.onChange(maskCnpj(value));

    if (maskCnpj(value).length != 18) return;

    if (validateCnpj(value)) {
      const toastId = toast.loading("Buscando dados do CNPJ, aguarde...");

      try {
        const cnpjData = await getCnpj(removeMask(value));
        handleCnpj(cnpjData);
        setValidateDocument(true);
      } catch (err) {
        toast.error("CNPJ inválido/inexistente!");
      }

      toast.dismiss(toastId);
    } else {
      toast.error("CNPJ inválido!", 2000);
      setValidateDocument(false);
    }
  };

  const handleBlur = async (e) => {
    const { value } = e.target;

    if (!validateCnpj(value)) {
      toast.error("CNPJ inválido!", 2000);
      setValidateDocument(false);
    }
  };

  return (
    <Input
      {...field}
      onBlur={handleBlur}
      minLength={18}
      onChange={handleInput}
      invalid={!validateDocument}
    />
  );
};
