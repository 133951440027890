import axios from "axios";

export default async function listCompanies(page = 1, limit = 10, filter) {
  const filterStr = filter ? encodeURIComponent(JSON.stringify(filter)) : "";

  const { data } = await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/v1/empresas/?page=${page}&limit=${limit}&filter=${filterStr}`
    )
    .then((res) => res)
    .catch((error) => {
      throw error.response.data.data;
    });

  return data;
}
