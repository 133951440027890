import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from "reactstrap";

const ModalConfirm = ({ modal, toggle, handleConfirm, title }) => {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader style={{ paddingBlock: "18px" }}>
        <span
          style={{ fontSize: "16px", letterSpacing: "0.2px", color: "black" }}
        >
          Confirmar
        </span>
      </ModalHeader>
      <ModalBody style={{ paddingBlock: "20px" }} toggle={toggle}>
        <span style={{ color: "#333", fontSize: "15px", lineHeight: "25px" }}>
          {title}
        </span>
      </ModalBody>
      <ModalFooter style={{ padding: "10px" }}>
        <Button color="info" onClick={handleConfirm}>
          Confirmar
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;
