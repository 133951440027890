export default function SelectDocumentType({ setValue, value }) {
  return (
    <select
      className="form-control"
      onChange={({ target: { value } }) => setValue(value)}
      value={value || 'cnpj'}
    >
      <option value="cnpj" selected>
        CNPJ
      </option>
      <option value="cpf">CPF</option>
      <option value="outro">Outro</option>
    </select>
  );
}
