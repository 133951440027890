import axios from "axios";
import UseStorage from "hooks/UseStorage";

export default async function getCompanies() {
  const token = UseStorage("get", "token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios
    .get(`${process.env.REACT_APP_AUTH_SERVER}/empresa`, config)
    .then((res) => res)
    .catch((error) => {
      throw new Error(error.message);
    });

  return data;
}
