import ListFees from "components/ListFees";
import { toast } from "react-toastify";
import { Button, Table } from "reactstrap";

const PricingRanges = ({
  isProductEnabled,
  priceScheme,
  ranges,
  setRanges,
}) => {
  const updateValuesTaxes = (name, value, indexRow) => {
    const newList = ranges.map((item, index) => {
      if (index !== indexRow) return item;

      return {
        ...item,
        [name]: value,
      };
    });
    setRanges(newList);
  };

  const onDeleteRow = (indexRow) => {
    const newList = ranges.filter((_, index) => {
      return index !== indexRow;
    });
    setRanges(newList);
  };

  const addTrTable = () => {
    ranges.map((element) => {
      element.price = parseFloat(element.price);
    });
    let surplusValue = 0;
    if (
      ranges[ranges.length - 1]?.start_quantity >=
      ranges[ranges.length - 1]?.end_quantity
    ) {
      toast.error("Término não pode ser menor ou igual que o Início");
    } else {
      ranges.forEach((element, index) => {
        if (element.overage_price) {
          const currentValue = element?.overage_price;
          surplusValue = currentValue;
        }
        ranges[index].overage_price = null;
      });

      if (ranges[ranges.length - 1].overage_price === null) {
        ranges[ranges.length - 1].overage_price = surplusValue;
      }

      ranges[0].start_quantity = 0;

      setRanges([
        ...ranges,
        {
          start_quantity: ranges[ranges.length - 1]?.end_quantity
            ? Number(ranges[ranges.length - 1]?.end_quantity) + 1
            : 0,
          end_quantity: null,
          price: null,
          overage_price: ranges[ranges.length - 1]?.overage_price
            ? Number(ranges[ranges.length - 1]?.overage_price)
            : null,
        },
      ]);
    }
  };

  return (
    <>
      <label>Faixas *</label>
      <Table className="tablesorter" responsive>
        <thead style={{ color: "#fff" }}>
          <tr>
            <td className="border-none">Início</td>
            <td className="border-none">Término</td>
            <td className="border-none">Preço</td>
            <td className="border-none">Excedente</td>
          </tr>
        </thead>
        <tbody>
          {ranges.map((trItem, indexRow) => (
            <ListFees
              isEnableEditing={!isProductEnabled}
              priceScheme={priceScheme}
              updateValuesTaxes={updateValuesTaxes}
              onDeleteRow={onDeleteRow}
              trItem={trItem}
              indexRow={indexRow}
              trList={ranges}
            ></ListFees>
          ))}
        </tbody>
      </Table>
      <Button
        onClick={() => {
          (ranges[ranges.length - 1] !== ranges[0] &&
            ranges[ranges.length - 1]?.end_quantity == null) ||
          0
            ? toast.error("Preencha o campo de Término!")
            : addTrTable();
        }}
        color="info"
        style={{ marginBottom: "30px" }}
      >
        Adicionar Faixa
      </Button>
    </>
  );
};

export default PricingRanges;
