import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Input } from "reactstrap";

export const InputPassword = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ position: "relative" }}>
      <Input {...props} type={showPassword ? "text" : "password"} />
      <div
        style={{
          position: "absolute",
          right: "20px",
          top: "50%",
          transform: "translate(0, -50%)",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={togglePasswordVisibility}
      >
        {showPassword ? (
          <BsEye style={{ color: "#cccccc", width: "18px", height: "18px" }} />
        ) : (
          <BsEyeSlash style={{ width: "18px", height: "18px" }} />
        )}
      </div>
    </div>
  );
};
