import { useHistory } from "react-router-dom";
import "../../assets/css/global.css";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import { toast, ToastContainer } from "react-toastify";
import postUser from "repositories/users/postUser";
import CreateUserForm from "components/Forms/CreateUserForm";
import CreateCompanyForm from "components/Forms/CreateCompanyForm";

function CreateUser() {
  document.title = "Criar Usuário";

  const history = useHistory();

  const handleSubmit = async (data) => {
    try {
      await postUser({
        ...data,
        funcionarios: "[]",
        permissoes: "[]",
        loginapp: 0,
        loginweb: 1,
        tipo: "gestor_sistema",
      });

      toast.success("Usuário criado com sucesso!", { autoClose: 500 });

      setTimeout(() => {
        history.push("/admin/usuarios");
      }, 2000);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="title">Dados do Usuário</h3>
              </CardHeader>
              <CardBody>
                <CreateUserForm onSubmit={handleSubmit} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CreateUser;
