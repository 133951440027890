import axios from "axios";
import UseStorage from "hooks/UseStorage";

export default async function putUser(id, body) {
  const token = UseStorage("get", "token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios
    .put(`${process.env.REACT_APP_AUTH_SERVER}/usuario/${id}`, body, config)
    .then((res) => res)
    .catch((error) => {
      console.log(error);
      throw new Error(error.response.data.message);
    });

  return data;
}
