import { Table } from "reactstrap";

export default function TableList({ columns, rows }) {
  return rows.length > 0 ? (
    <Table className="tablesorter" responsive>
      <thead className="text-primary">
        <tr>
          {columns.map((column) => (
            <th>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "17px",
        background: "#353855",
        padding: "8px",
        borderRadius: "5px",
        color: "#FFFFFF",
      }}
    >
      Nenhuma informação encontrada!
    </div>
  );
}
