import { Input, Button } from "reactstrap";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const ListFees = ({
  changeResultRequest,
  updateValuesTaxes,
  onDeleteRow,
  trItem,
  indexRow,
  trList,
  priceScheme,
  isEnableEditing,
}) => {
  const [startQuantity, setStartQuantity] = useState(
    trItem?.start_quantity || 0,
  );

  const maskReais = (value) => {
    return (Number(value?.replace(/\D/g, "")) / 100)?.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const [endQuantity, setEndQuantity] = useState(trItem?.end_quantity);
  const [price, setPrice] = useState(trItem?.price);
  const [overagePrice, setOveragePrice] = useState(
    trList[trList.length - 1] === trItem ? trItem?.overage_price : null,
  );

  useEffect(() => {
    setStartQuantity(trList[0].start_quantity);
    setEndQuantity(trList[0].end_quantity);
    setOveragePrice(trList[0].overage_price);
    setPrice(trList[0].price);
  }, [trList]);

  const messageError = (message) => {
    toast.error(message);
  };

  return (
    <tr key={indexRow}>
      <td className="border-none">
        <ToastContainer theme="colored" />
        <Input
          name="start_quantity"
          type="number"
          value={startQuantity || 0}
          disabled
          required={priceScheme === "flat" ? false : true}
          onChange={(e) => {
            setStartQuantity(Number(e.currentTarget.value));
            updateValuesTaxes(
              "start_quantity",
              Number(e.currentTarget.value),
              indexRow,
            );
          }}
        />
      </td>
      <td className="border-none">
        <Input
          name="end_quantity"
          type="text"
          required={priceScheme === "flat" ? false : true}
          value={endQuantity || ""}
          disabled={
            indexRow !== trList.length - 1 ? true : false || isEnableEditing
          }
          onChange={(e) => {
            if (Number(e.currentTarget.value) > 9999) {
              messageError("Término só aceita valores ate 9999");
              changeResultRequest(true);
            } else {
              setEndQuantity(Number(e.currentTarget.value));
              updateValuesTaxes(
                "end_quantity",
                Number(e.currentTarget.value),
                indexRow,
              );
            }
          }}
        />
      </td>
      <td className="border-none">
        <Input
          required={priceScheme === "flat" ? false : true}
          name="price"
          type="text"
          disabled={
            indexRow !== trList.length - 1 ? true : false || isEnableEditing
          }
          value={price || ""}
          onChange={(e) => {
            setPrice(
              maskReais(e.currentTarget.value)
                ?.replace(/[^\d]+/g, ".")
                .substring(1),
            );
            updateValuesTaxes(
              "price",
              maskReais(e.currentTarget.value)
                ?.replace(/[^\d]+/g, ".")
                .substring(1),
              indexRow,
            );
          }}
        />
      </td>
      <td className="border-none">
        <Input
          required={priceScheme === "flat" ? false : true}
          name="overage_price"
          value={indexRow !== trList.length - 1 ? "" : trItem?.overage_price}
          type="text"
          onChange={(e) => {
            if (Number(e.currentTarget.value) > 9999) {
              messageError("Excedente só aceita valores ate 9999");
              changeResultRequest(true);
            } else {
              setOveragePrice(Number(e.currentTarget.value));
              updateValuesTaxes(
                "overage_price",
                maskReais(e.currentTarget.value)
                  ?.replace(/[^\d]+/g, ".")
                  .substring(1),
                indexRow,
              );
            }
          }}
          disabled={
            indexRow !== trList.length - 1 ? true : false || isEnableEditing
          }
        />
      </td>
      <td className="border-none font-icon-detail">
        {indexRow === trList.length - 1 && indexRow !== 0 && (
          <Button
            onClick={() => onDeleteRow(indexRow)}
            className="btn-icon deleteBtn"
            color="danger"
            size="sm"
          >
            <i className="fa fa-times deleteBtn" />
          </Button>
        )}
      </td>
    </tr>
  );
};

export default ListFees;
