import SearchableDropdown from "components/SearchableDropdown";
import { useEffect, useState } from "react";
import getCompanies from "repositories/companies/getCompanies";

export default function SelectCompanies(props) {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompaniesList();
  }, []);

  const getCompaniesList = async () => {
    const response = await getCompanies();
    setCompanies(response);
  };

  return (
    <SearchableDropdown
      items={companies}
      displayProperty="nomefantasia"
      placeholder="Pesquisar empresa..."
      onSelect={props.onSelect}
    />
  );
}
