import "../../assets/css/global.css";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import CreateCompanyForm from "components/Forms/CreateCompanyForm";

export default function CreateCompany() {
  document.title = "Criar Empresa";

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="title">Dados da Empresa</h3>
              </CardHeader>
              <CardBody>
                <CreateCompanyForm />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
