import "../assets/css/global.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Input,
  Label,
} from "reactstrap";
import { putLogin } from "repositories/login";
import { toast, ToastContainer } from "react-toastify";
import UseStorage from "hooks/UseStorage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "assets/img/react-logo.png";
import { useEffect } from "react";
import { getMyUser } from "repositories/users/getMyUser";

const LOGGED_IN_DEFAULT_PATH = "/admin/empresas";

export default function Login() {
  document.title = "Login";
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { login, password } = event.target.elements;

    try {
      const response = await putLogin(login.value, password.value);
      await checkAdminAccess(response.token);

      // salvar token para requests do authserver
      UseStorage("set", "token", response.token);

      toast.success("Login com sucesso!", { autoClose: 500 });

      redirectToDefaultPath();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const checkAdminAccess = async (token) => {
    const response = await getMyUser(token); // TODO: usar novo campo para a checagem. ainda não está no auth.

    // salvar empresas para o select ded usuário
    UseStorage("set", "empresas", response.empresas);

    if (!response) {
      throw new Error(
        "O usuário não possui permissões para acessar o sistema admin",
      );
    }
  };

  const redirectToDefaultPath = () => {
    history.push(LOGGED_IN_DEFAULT_PATH);
  };

  useEffect(() => {
    const token = UseStorage("get", "token");
    if (token) {
      redirectToDefaultPath();
    }
  }, []);

  return (
    <>
      <div
        className="main-panel"
        style={{
          height: "100vh",
          backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <ToastContainer theme="colored" />
        <Card
          style={{
            width: "50%",
            maxWidth: "600px",
            borderRadius: "10px",
            padding: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CardHeader>
            <img src={logo} style={{ width: "60px", marginBottom: "20px" }} />
            <h3
              className="title"
              style={{
                color: "white",
              }}
            >
              Acessar o <span style={{ color: "#8773b3" }}>ADMIN</span>
            </h3>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Label
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Login
              </Label>
              <Input
                style={{
                  backgroundColor: "white",
                }}
                className="text-dark"
                name="login"
                placeholder="Digite seu login"
                type="text"
                required
              />

              <Label
                style={{
                  marginTop: "20px",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Senha
              </Label>
              <Input
                style={{
                  backgroundColor: "white",
                }}
                className="text-dark"
                name="password"
                placeholder="Digite sua senha"
                type="password"
                required
              />

              <Button
                style={{
                  marginTop: "20px",
                }}
                className="btn-fill"
                color="primary"
                type="submit"
              >
                Logar
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
