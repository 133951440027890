import axios from "axios";

export const putLogin = async (login, password) => {
  const { data } = await axios
    .post(`${process.env.REACT_APP_AUTH_SERVER}/auth/login`, {
      login,
      senha: password,
    })
    .then((res) => res)
    .catch((error) => {
      throw error.response.data;
    });

  return data;
};
