import { useEffect, useMemo, useState } from "react";
import "../../assets/css/global.css";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import EditCompanyForm from "components/Forms/EditCompanyForm";
import getCompany from "repositories/companies/getCompany";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function EditCompany() {
  const { id } = useParams();
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);

  document.title = "Editar Empresa";

  const api = async () => {
    setLoading(true);
    const data = await getCompany(id);

    setCompany(data);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    api();
  }, []);

  const EditCompany = useMemo(() => {
    return <EditCompanyForm companyInitialValues={company} id={id} />;
  }, [company, loading]);

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="title">Dados da Empresa</h3>
              </CardHeader>
              <CardBody>
                {!loading && company ? (
                  EditCompany
                ) : (
                  <p>Carregando...</p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
