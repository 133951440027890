import axios from "axios";
import UseStorage from "hooks/UseStorage";
import { removeTokenFromLocalStorage } from "utils/token";

export default async function listUsers(page = 1, limit = 10, filter) {
  const token = UseStorage("get", "token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios
    .get(
      `${process.env.REACT_APP_AUTH_SERVER}/usuario?limit=${limit}&page=${page}&field=${filter.field}&value=${filter.value}`,
      config,
    )
    .catch((err) => {
      if (err.response.status == 401) {
        removeTokenFromLocalStorage();
      }
    });

  return data;
}
