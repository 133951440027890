import React, { useState, useEffect } from "react";
import "../../assets/css/global.css";
import load from "../../assets/img/load.gif";
import ModalConfirm from "components/ModalConfirm/";
import "../../assets/css/global.css";
import { Card, CardBody, Row, Col } from "reactstrap";
import Pagination from "react-responsive-pagination";
import listUsers from "repositories/users/listUsers";
import deleteUser from "repositories/users/deleteUser";
import Actions from "components/Actions";
import { toast, ToastContainer } from "react-toastify";
import ListHeader from "components/ListHeader";
import TableList from "components/Table";
import UseStorage from "hooks/UseStorage";
import { removeTokenFromLocalStorage } from "utils/token";

export default function ListUsers() {
  document.title = "Listar Usuários";

  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    field: "",
    value: "",
  });

  const [loading, setLoading] = useState(false);

  const [userName, setUserName] = useState("");
  const [idToDelete, setIdToDelete] = useState("");

  useEffect(() => {
    getUsersList();
  }, [currentPage, filter]);

  const getUsersList = async () => {
    setLoading(true);

    try {
      const response = await listUsers(currentPage, 10, filter);
      setUsersList(response?.usuarios);
      setTotalPages(response?.total_pages);
    } catch (err) {}

    setLoading(false);
  };

  const handleFilter = (e) => {
    setFilter(e);
  };

  const handleDisableUser = async () => {
    setLoading(true);

    try {
      await deleteUser(idToDelete);
      setModal(false);
      toast.success("Usuário desativado com sucesso!", { autoClose: 500 });
    } catch (err) {
      toast.error(err);
    }

    getUsersList();
    setLoading(false);
  };

  const [modal, setModal] = useState(false);
  const toggleDeleteModal = (item) => {
    setUserName(item?.nome);
    setIdToDelete(item?.id);
    setModal(!modal);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <ModalConfirm
          modal={modal}
          handleConfirm={async () => await handleDisableUser()}
          toggle={() => setModal(!modal)}
          title={`Deseja realmente desativar o usuário: ${userName}?`}
        />
        <div>
          <h1>Usuários</h1>
        </div>
        <Row>
          <Col md="12">
            <Card>
              <ListHeader
                createLink="usuarios/criar"
                createBtnText="Criar Usuário"
                fieldsToFilter={[
                  { value: "nome", name: "Nome" },
                  { value: "login", name: "Login" },
                  { value: "email", name: "Email" },
                ]}
                handleFilter={handleFilter}
              />

              <CardBody style={{ minHeight: "740px" }}>
                {loading ? (
                  <Row style={{ justifyContent: "center" }}>
                    <img width={300} src={load} alt="load" />
                  </Row>
                ) : (
                  <TableList
                    columns={[
                      "Nome",
                      "Empresa",
                      "Email",
                      "Login",
                      "Pin do app",
                      "Status",
                      "Tipo do usuário",
                      "Ações",
                    ]}
                    rows={usersList?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.nome}</td>
                        <td>{item?.empresa}</td>
                        <td>{item?.email}</td>
                        <td>{item?.login}</td>
                        <td>{item?.pinapp}</td>
                        <td>{item?.status ? "Ativo" : "Inativo"}</td>
                        <td>{item?.tipo}</td>
                        {item?.tipo == "gestor_sistema" ? (
                          <Actions
                            link={`usuarios/editar/${item?.id}`}
                            toggleConfirmModal={() => toggleDeleteModal(item)}
                          />
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    ))}
                  />
                )}
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={(page) => handlePageChange(page)}
                  previousLabel="Anterior"
                  nextLabel="Próximo"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
