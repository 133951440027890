const SelectYesOrNo = ({
  name,
  onChange,
  stateComponent,
  positiveValue = "Ativo",
  negativeValue = "Inativo",
  disabled,
}) => {
  const handleChange = (e) => {
    const value = e.target.value === "true";
    onChange({ target: { name, value } });
  };

  return (
    <select
      className="form-control"
      name={name}
      onChange={handleChange}
      value={stateComponent}
      disabled={disabled}
    >
      <option value={true}>{positiveValue}</option>
      <option value={false} selected>
        {negativeValue}
      </option>
    </select>
  );
};

export default SelectYesOrNo;
