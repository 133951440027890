import axios from "axios";
import UseStorage from "hooks/UseStorage";

export const getMyUser = async (token) => {
  if (!token) {
    token = UseStorage("get", "token");
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios
    .get(`${process.env.REACT_APP_AUTH_SERVER}/usuario/meu-usuario`, config)
    .then((res) => res)
    .catch((error) => {
      throw new Error(error.response.data.message);
    });

  return data;
};
