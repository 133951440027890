import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";

const SearchableDropdown = ({
  items,
  displayProperty,
  placeholder,
  onSelect,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSelect = (item) => {
    setSearchTerm(item[displayProperty]);
    setDropdownOpen(false);
    onSelect(item);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setDropdownOpen(true);
  };

  const filteredItems = items?.filter((item) =>
    item[displayProperty].toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="w-100"
      style={{ marginBottom: "20px", maxHeight: '200px' }}
    >
      <DropdownToggle
        tag="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        className="w-100"
        style={{ padding: 0, maxHeight: '200px', height: 'auto' }}
      >
        <Input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
          onClick={() => !dropdownOpen && setDropdownOpen(true)}
        />
      </DropdownToggle>
      <DropdownMenu
        className="w-100"
        style={{
          maxHeight: '300px',
          height: '300px',
          overflow: 'auto',
          height: 'auto',
        }}>
        {filteredItems.length === 0 ? (
          <DropdownItem disabled>No items found</DropdownItem>
        ) : (
          filteredItems.map((item) => (
            <DropdownItem key={item.id} onClick={() => handleSelect(item)}>
              {item[displayProperty]}
            </DropdownItem>
          ))
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchableDropdown;
