import React from "react";

const SelectBillingTriggerDay = ({ ...props }) => {
  const triggerMonthDays = [5, 10, 15, 20, 25, 30];

  const options = triggerMonthDays.map((value) => {
    return (
      <option key={value} value={value}>
        {value}
      </option>
    );
  });

  return (
    <select {...props}>
      <option value="0">Cobrança Imediata</option>
      {options}
    </select>
  );
};

export default SelectBillingTriggerDay;
