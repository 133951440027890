import React, { useState, useEffect } from "react";
import "../../assets/css/global.css";
import load from "../../assets/img/load.gif";
import { toast, ToastContainer } from "react-toastify";
import ModalConfirm from "components/ModalConfirm/";
import "../../assets/css/global.css";
import { Card, CardBody, Row, Col } from "reactstrap";
import Pagination from "react-responsive-pagination";
import Actions from "components/Actions";
import listAccesses from "repositories/accesses/listAccesses";
import deleteAccess from "repositories/accesses/deleteAccess";
import ListHeader from "components/ListHeader";
import TableList from "components/Table";

export default function ListAccesses() {
  document.title = "Listar Acessos";

  const [accessesList, setAccessesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    field: "",
    value: "",
  });

  const [loading, setLoading] = useState(false);

  const [accessName, setAccessName] = useState("");
  const [idToDelete, setIdToDelete] = useState("");

  useEffect(() => {
    getAccessesList();
  }, [currentPage, filter]);

  const getAccessesList = async () => {
    setLoading(true);
    const response = await listAccesses(currentPage, 10, filter);
    setAccessesList(response?.acessos);
    setTotalPages(response?.total_pages);
    setLoading(false);
  };

  const handleFilter = (e) => {
    setFilter(e);
  };

  const handleDeleteAccess = async () => {
    setLoading(true);

    try {
      await deleteAccess(idToDelete);
      setModal(false);
      toast.success("Acesso apagado com sucesso!", { autoClose: 500 });
    } catch (err) {
      toast.error(err);
    }

    getAccessesList();
    setLoading(false);
  };

  const [modal, setModal] = useState(false);
  const toggleDeleteModal = (item) => {
    setAccessName(item?.usuario);
    setIdToDelete(item?.id);
    setModal(!modal);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <ModalConfirm
          modal={modal}
          handleConfirm={handleDeleteAccess}
          toggle={() => setModal(!modal)}
          title={`Deseja realmente apagar o acesso ${accessName}?`}
          subTitle=""
        />
        <div>
          <h1>Acessos</h1>
        </div>
        <Row>
          <Col md="12">
            <Card>
              <ListHeader
                createLink="acessos/criar"
                createBtnText="Criar Acesso"
                fieldsToFilter={[
                  { value: "usuario", name: "Usuário" },
                  { value: "login", name: "Login" },
                  { value: "empresa", name: "Empresa" },
                ]}
                handleFilter={handleFilter}
              />

              <CardBody style={{ minHeight: "740px" }}>
                {loading ? (
                  <Row style={{ justifyContent: "center" }}>
                    <img width={300} src={load} alt="load" />
                  </Row>
                ) : (
                  <TableList
                    columns={["Empresa", "Usuário", "Ações"]}
                    rows={accessesList?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.empresa}</td>
                        <td>{item?.usuario}</td>
                        <Actions toggleConfirmModal={() => toggleDeleteModal(item)} />
                      </tr>
                    ))}
                  />
                )}
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={(page) => handlePageChange(page)}
                  previousLabel="Anterior"
                  nextLabel="Próximo"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
