import axios from "axios";

export default async function getCep(cep) {
  const { data } = await axios
    .get(`https://viacep.com.br/ws/${cep}/json/`)
    .then((res) => res)
    .catch((error) => {
      console.error("Erro ao buscar o CEP!", error);
    });

  return data;
}
