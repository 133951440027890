import SelectBillingTriggerDay from "components/SelectBillingTriggerDay";
import SelectBillingTriggerTypes from "components/SelectBillingTriggerTypes";
import SelectIntervalCount from "components/SelectIntervalCount";
import SelectYesOrNo from "components/SelectYesOrNo";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { maskPhone } from "utils/masks";
import { maskBrl } from "utils/masks";
import { maskLandline } from "utils/masks";
import { maskCaepf } from "utils/masks";
import { maskCpf } from "utils/masks";
import { maskCnpj } from "utils/masks";
import { maskDbName } from "utils/masks";
import PricingRanges from "components/PricingRanges";
import { CepInput } from "components/CepInput";
import { removeMask } from "utils/masks";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CnpjInput } from "components/CnpjInput";
import { formatIsoDateToStr } from "utils/date";
import putCompany from "repositories/companies/putCompany";
import { SelectSearchUsers } from "components/SelectSearchUsers";
import SelectPlans from "components/SelectPlans";
import SelectDocumentType from "components/SelectDocumentType";
import { CpfInput } from "components/CpfInput";
import ModalConfirm from "components/ModalConfirm";

export default function EditCompanyForm({ companyInitialValues, id }) {
  const [isAssociation, setIsAssociation] = useState(false);
  const [isProductEnabled, setIsProductEnabled] = useState(false);
  const [isOpenPlanType, setIsOpenPlanType] = useState(false);
  const [isTaxEnabled, setIsTaxEnabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [documentType, setDocumentType] = useState("cnpj");
  const [validateDocument, setValidateDocument] = useState(false);
  const [hasASignature, setHasASignature] = useState(false);
  const [listCompanyAssociation, setListCompanyAssociation] = useState([]);
  const [planKey, setPlanKey] = useState();
  const [productKey, setProductKey] = useState();
  const [ranges, setRanges] = useState([
    {
      start_quantity: 0,
      end_quantity: 0,
      price: 0,
      overage_price: null,
    },
  ]);

  const history = useHistory();

  const verifyDocument = (document) => {
    if (document.length === 11) {
      return "cpf";
    } else if (document.length === 14) {
      return "cnpj";
    }
    return "outro";
  };

  const verifyMask = (typeDocument, document) => {
    if (typeDocument === "cnpj") return maskCnpj(document);
    if (typeDocument === "cpf") return maskCpf(document);
    return document;
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const getDefaultValues = () => {
    const data = companyInitialValues;
    const produto = data?.produto;
    const assinatura = data?.assinatura;

    const responseVerifyDocument = verifyDocument(data.cnpj);

    setDocumentType(verifyDocument(data.cnpj));
    setPlanKey(assinatura?.keyplano || "");
    setProductKey(produto.length > 0 ? produto[0].precificacao[0]?.keyitem : "");
    setValidateDocument(true);
    setListCompanyAssociation(data?.empresasAssociadas);
    setHasASignature(data?.possuiAssociacao);

    // SETAR MANUALMENTE OS VALORES, DEFAULT FUNCIONA EM STAGING MAS N EM PROD

    setValue("cnpj", verifyMask(responseVerifyDocument, data.cnpj));
    setValue("razaosocial", data.razaosocial);
    setValue("nomefantasia", data.nomefantasia);
    setValue("nomedobanco", data.nomedobanco);
    setValue("emailcobranca", data.emailcobranca);
    setValue("number", data.telefone);
    setValue("cpfresponsavel", data.cpfresponsavel);
    setValue("cno", data.cno);
    setValue("caepf", data.caepf);
    setValue("dtinserido", data.dtinserido);
    setValue("cep", data.cep);
    setValue("endereco", data.endereco);
    setValue("bairro", data.bairro);
    setValue("numero", data.numero);
    setValue("complemento", data.complemento);
    setValue("cidade", data.cidade);
    setValue("estado", data.estado);
    setValue("fuso", data.fuso);
    setValue("status", data.status);
    setValue("pontooffline", data.pontooffline);
    setValue("ocultarsaldos", data.ocultarsaldos);
    setValue("customizarfuso", data.customizarfuso);
    setValue("ocultarhistorico", data.ocultarhistorico);
    setValue("terceirizacao", data.terceirizacao);
    setValue("billing_trigger_type", assinatura?.gerarcobranca ?? "beginning_of_period");
    setValue("interval_count", assinatura?.qtdinterval ?? 1);
    setValue("billing_trigger_day", assinatura?.diadacobranca ?? 0);
    setValue("plano", data?.plano?.nome ?? "basico");
    setValue("priceOne", produto.length > 0 ? produto[0].precificacao[0].precobase : 0);
    setValue("limitefuncionarios", assinatura?.limitefuncionarios ?? "");
    setValue("payment_method_code", assinatura?.metododepagamento ?? "bank_slip");
    setValue("schema_type", "flat");
    setValue("taxa", 0);
    setValue("valortaxa", 0);

    if (data.produto.length > 0) {
      setValue("schema_type", produto[0]?.tipodeproduto);
      setValue("taxa", !!produto[0]?.taxa);
      setValue("valortaxa", produto[0]?.valortaxa);
      setIsTaxEnabled(!!produto[0].taxa);
    }

    const defaultRanges =
      produto.length > 0
        ? produto[0].precificacao.map((e) => ({
            start_quantity: e.faixa_inicio,
            end_quantity: e.faixa_fim,
            price: e.precofaixa,
            overage_price: e.excedente,
          }))
        : [
            {
              start_quantity: "",
              end_quantity: "",
              price: "",
              overage_price: "",
            },
          ];

    setRanges(defaultRanges);
  };

  useEffect(() => {
    getDefaultValues();
  }, [companyInitialValues]);

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((errorKey) => {
        toast.error(`Campo "${errorKey}" inválido`);
      });
    }
  }, [Object.keys(errors)]);

  const isFixedPrice = watch("schema_type") == "flat";
  const isMobile = watch("phone_type") == "mobile";
  const isAnnualBilling = watch("interval_count") == 12;
  const isMonthlyBilling = watch("interval_count") == 1;
  const isTriggerDayOfMonth = watch("billing_trigger_type") == "day_of_month";
  const isDocumentTypeValid = documentType != "outro";

  useEffect(() => {
    if (!isDocumentTypeValid) setIsProductEnabled(false);
  }, [isDocumentTypeValid]);

  useEffect(() => {
    if (!isMonthlyBilling && isTriggerDayOfMonth)
      setValue("billing_trigger_type", "beginning_of_period");
  }, [isMonthlyBilling, isTriggerDayOfMonth]);

  const handleSetResultOptionSelectUser = (userSelected) => {
    setSelectedUser(userSelected);
    setValue("interval_count", userSelected?.EmpresaModel?.assinatura?.qtdinterval);
    setValue("billing_trigger_day", userSelected?.EmpresaModel?.assinatura?.diadacobranca);
    setValue("payment_method_code", userSelected?.EmpresaModel?.assinatura?.metododepagamento);
  };

  const onSubmit = (data) => {
    delete data.nomedobanco;

    const body = {
      ...data,
      associar: +isAssociation,
      possuiAssociacao: hasASignature,
      cnpj: removeMask(data.cnpj),
      editarvindi: isProductEnabled ? 1 : 0,
      fuso: -3,
      numero: data.numero,
      interval: "months",
      telefone: data.number,
      phones: [
        {
          phone_type: data.phone_type,
          number: data.number,
          extension: null,
        },
      ],
      taxa: isTaxEnabled,
      dataProduct: {
        id_item: productKey,
        pricing_schema: {
          price: isFixedPrice ? data.priceOne : 0,
          schema_type: data.schema_type,
          pricing_ranges: !isFixedPrice
            ? ranges.map((range) => ({
                ...range,
                price: parseFloat(range.price),
                overage_price: parseFloat(range.overage_price),
              }))
            : "",
        },
      },
      usuario: selectedUser,
      dataPlan: {
        keyplano: planKey,
        interval: "months",
        interval_count: Number(data.interval_count),
        billing_trigger_type: data.billing_trigger_type,
        billing_trigger_day: Number(data.billing_trigger_day),
        payment_method_code: data.payment_method_code,
        limitefuncionarios: Number(data.limitefuncionarios),
      },
    };

    if (validateDocument && documentType == "cnpj") {
      updateCompany(body);
    } else if (documentType !== "cnpj") {
      updateCompany(body);
    }
  };

  const updateCompany = async (body) => {
    const toastId = toast.loading("Atualizando empresa...");
    try {
      await putCompany(id, body);

      toast.success("Empresa atualizada com sucesso!", 4000);

      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (err) {
      toast.error(err.message, 4000);
      console.error(err);
    }
    toast.dismiss(toastId);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="3">
          <label>Tipo de documento</label>
          <SelectDocumentType setValue={setDocumentType} value={documentType} />
        </Col>
        <Col md="3">
          <label>Documento *</label>
          <Controller
            name="cnpj"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => {
              if (documentType == "cnpj") {
                return (
                  <CnpjInput
                    setValue={setValue}
                    setValidateDocument={(bool) => setValidateDocument(bool)}
                    validateDocument={validateDocument}
                    {...field}
                  />
                );
              } else if (documentType == "cpf") {
                return <CpfInput {...field} />;
              } else {
                return <Input {...field} />;
              }
            }}
          />
        </Col>
        <Col md="3">
          <label>Razão Social *</label>
          <Controller
            name="razaosocial"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Nome Fantasia *</label>
          <Controller
            name="nomefantasia"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Nome do banco de dados *</label>
          <Controller
            name="nomedobanco"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                {...field}
                required
                disabled
                onChange={(e) => field.onChange(maskDbName(e.target.value))}
              />
            )}
          />
        </Col>
        <Col md="3">
          <label>Email *</label>
          <Controller
            name="emailcobranca"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} type="email" required />}
          />
        </Col>
        <Col md="3">
          <label>Tipo de Celular *</label>
          <Controller
            name="phone_type"
            control={control}
            render={({ field }) => (
              <select {...field} className="form-control">
                <option value="mobile" selected>
                  Celular
                </option>
                <option value="landline">Telefone</option>
              </select>
            )}
          />
        </Col>

        <Col md="3">
          <label>{isMobile ? "Celular" : "Telefone"} *</label>
          <Controller
            name="number"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                {...field}
                required
                onChange={(e) => {
                  field.onChange(
                    isMobile ? maskPhone(e.target.value) : maskLandline(e.target.value)
                  );
                }}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>CPF do responsável</label>
          <Controller
            name="cpfresponsavel"
            control={control}
            render={({ field }) => (
              <Input {...field} onChange={(e) => field.onChange(maskCpf(e.target.value))} />
            )}
          />
        </Col>
        <Col md="3">
          <label>CNO</label>
          <Controller
            name="cno"
            control={control}
            render={({ field }) => <Input {...field} maxLength={12} />}
          />
        </Col>
        <Col md="3">
          <label>CAEPF</label>
          <Controller
            name="caepf"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                maxLength={18}
                onChange={(e) => field.onChange(maskCaepf(e.target.value))}
              />
            )}
          />
        </Col>
        <Col md="3">
          <label>Data de criação</label>
          <Controller
            name="dtinserido"
            control={control}
            render={({ field }) => <Input {...field} disabled />}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>CEP</label>
          <Controller
            name="cep"
            control={control}
            render={({ field }) => <CepInput setValue={setValue} {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Endereço</label>
          <Controller
            name="endereco"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Bairro</label>
          <Controller
            name="bairro"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Número</label>
          <Controller
            name="numero"
            control={control}
            render={({ field }) => <Input {...field} type="number" />}
          />
        </Col>
        <Col md="3">
          <label>Complemento</label>
          <Controller
            name="complemento"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Cidade</label>
          <Controller
            name="cidade"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Estado</label>
          <Controller
            name="estado"
            control={control}
            render={({ field }) => <Input {...field} maxLength={2} placeholder="UF" />}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Fuso Horário</label>
          <Controller
            name="fuso"
            control={control}
            render={({ field }) => <Input {...field} type="number" />}
          />
        </Col>
        <Col md="3">
          <label>Status</label>
          <Controller
            name="status"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Ponto Offline</label>
          <Controller
            name="pontooffline"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Ocultar Saldos e Intervalo no Aplicativo</label>
          <Controller
            name="ocultarsaldos"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Fuso Customizado</label>
          <Controller
            name="customizarfuso"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Ocultar Histórico de Batidas no Aplicativo</label>
          <Controller
            name="ocultarhistorico"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Terceirização</label>
          <Controller
            name="terceirizacao"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Tipo de Plano *</label>
          <Controller
            name="plano"
            control={control}
            render={({ field }) => (
              <SelectPlans
                openModal={() => {
                  setIsOpenPlanType(true);
                }}
                {...field}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {hasASignature ? (
            <h3 className="title">Desassociar Empresa</h3>
          ) : (
            <h3 className="title">Associar Empresa</h3>
          )}
        </Col>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col md="3">
          <label>Associar</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            disabled={!isProductEnabled}
            stateComponent={isAssociation}
            onChange={(e) => setIsAssociation(e.target.value)}
          />
        </Col>

        {isAssociation && (
          <Col md={3} style={{ marginTop: "-5px" }}>
            <label>Usuario </label>
            <SelectSearchUsers handleSetResultOptionSelectUser={handleSetResultOptionSelectUser} />
          </Col>
        )}

        {isDocumentTypeValid && (
          <Col md="6">
            <Button
              onClick={() => {
                setIsProductEnabled((prev) => !prev);
              }}
              tag="text"
              color="info"
            >
              Habilitar Produto
            </Button>
          </Col>
        )}

        <Col md="6">
          {hasASignature === true && (
            <div>
              <label>Empresas Associadas </label>
              <div className="text-white">
                {listCompanyAssociation.map((e) => e.razaosocial).join(", ")}
              </div>
            </div>
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col>
          <h3 className="title">Dados da Assinatura</h3>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <h4 className="title">Produto</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>Tipo de Cálculo *</label>
          <Controller
            name="schema_type"
            control={control}
            render={({ field }) => (
              <select {...field} disabled={!isProductEnabled} className="form-control">
                <option value="flat" selected>
                  Preço Fixo
                </option>
                <option value="step_usage">Fixo por Volume</option>
                <option value="volume_usage">Variavel por Volume</option>
              </select>
            )}
          />
        </Col>

        {isFixedPrice ? (
          <Col md="3">
            <label>Preço *</label>
            <Controller
              name="priceOne"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!isProductEnabled}
                  required
                  onChange={(e) => field.onChange(maskBrl(e.target.value))}
                />
              )}
            />
          </Col>
        ) : (
          <Col md="12">
            <PricingRanges
              isProductEnabled={isProductEnabled}
              priceScheme={watch("schema_type")}
              ranges={ranges}
              setRanges={setRanges}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col md="3">
          <label>Adicionar taxa *</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            stateComponent={isTaxEnabled}
            onChange={(e) => setIsTaxEnabled(e.target.value)}
            disabled={!isProductEnabled}
          />
        </Col>
        {isTaxEnabled ? (
          <Col md="3">
            <label>Taxa *</label>
            <Controller
              name="valortaxa"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!isProductEnabled}
                  required
                  onChange={(e) => field.onChange(maskBrl(e.target.value))}
                />
              )}
            />
          </Col>
        ) : null}
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col md="4">
          <h4 className="title">Plano</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>Forma de pagamento</label>
          <Controller
            name="payment_method_code"
            control={control}
            render={({ field }) => (
              <select
                {...field}
                disabled={!isProductEnabled || isAssociation}
                name="payment_method_code"
                className="form-control"
              >
                <option disabled selected>
                  Selecione o Método de Pagamento
                </option>
                <option value="bank_slip">Boleto Bancário</option>
                <option value="credit_card">Cartão de Crédito</option>
                <option value="pix">Pix</option>
              </select>
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Contagem de intervalo *</label>
          <Controller
            name="interval_count"
            control={control}
            render={({ field }) => (
              <SelectIntervalCount
                {...field}
                ref={null}
                disabled={!isProductEnabled || isAssociation}
                required
                className="form-control"
              />
            )}
          />
        </Col>

        {isAnnualBilling && (
          <Col md="3">
            <label>Limite de Funcionários *</label>
            <Controller
              name="limitefuncionarios"
              control={control}
              render={({ field }) => (
                <Input {...field} disabled={!isProductEnabled} max={100} required={isFixedPrice} />
              )}
            />
          </Col>
        )}

        <Col md="3">
          <label>Referência para data de geração da cobrança *</label>
          <Controller
            name="billing_trigger_type"
            control={control}
            render={({ field }) => (
              <SelectBillingTriggerTypes
                {...field}
                ref={null}
                intervalCount={watch("interval_count")}
                disabled={!isProductEnabled || isAssociation}
                required
                className="form-control"
              />
            )}
          />
        </Col>

        <Col md="3">
          <label>Dia de cobrança *</label>
          <Controller
            name="billing_trigger_day"
            control={control}
            render={({ field }) => (
              <SelectBillingTriggerDay
                {...field}
                ref={null}
                disabled={!isProductEnabled || isAssociation}
                required
                className="form-control"
              />
            )}
          />
        </Col>
      </Row>

      <Button disabled={false} className="btn-fill" color="primary" type="submit">
        Salvar
      </Button>
      <ModalConfirm
        modal={isOpenPlanType}
        handleConfirm={() => {
          setIsOpenPlanType(false);
          setValue("plano", "essencial");
        }}
        toggle={() => {
          setIsOpenPlanType(false);
          setValue("plano", "basico");
        }}
        title={"Deseja ativar o plano essencial para essa empresa?"}
        subTitle=""
      />
    </Form>
  );
}
