import axios from "axios";

export default async function deleteCompany(id) {
  await axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/v1/empresa/desativar-empresa/?id=${id}`,
    )
    .then((res) => res)
    .catch((error) => {
      throw error.response.data.data;
    });
}
