import SelectCompanies from "components/SelectCompanies";
import SelectUsers from "components/SelectUsers";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "reactstrap";

export default function CreateAccessForm({ onSubmit }) {
  const { handleSubmit, control } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="12">
          <label>Empresa</label>
          <Controller
            name="empresa"
            control={control}
            render={({ field }) => (
              <SelectCompanies onSelect={(item) => field.onChange(item.id)} />
            )}
          />
        </Col>
        <Col md="12">
          <label>Usuário</label>
          <Controller
            name="usuario"
            control={control}
            render={({ field }) => (
              <SelectUsers onSelect={(item) => field.onChange(item.id)} />
            )}
          />
        </Col>
      </Row>

      <Button
        disabled={false}
        className="btn-fill"
        color="primary"
        type="submit"
      >
        Salvar
      </Button>
    </Form>
  );
}
