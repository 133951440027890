import SearchableDropdown from "components/SearchableDropdown";
import { useEffect, useState } from "react";
import listUsers from "repositories/users/listGestores";

export default function SelectUsers({ onSelect }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async () => {
    const response = await listUsers();
    const users = response.map((user) => {
      const nome = user.nome || "Nome Não definido";
      const login = user.login || "Email Não definido";
      const displayName = `${nome} (${login})`;

      return {
        ...user,
        displayName,
      };
    });

    setUsers(users);
  };

  return (
    <SearchableDropdown
      items={users}
      displayProperty="displayName"
      placeholder="Pesquisar usuario..."
      onSelect={onSelect}
    />
  );
}
